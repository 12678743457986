.blink{
  animation: blink 5s infinite;
}
@keyframes blink{
  0% {
    color: white;
  }
  20% {
    color: #F2356D;
  }
  40% {
    color: white;
  }
  60% {
    color: #F2356D;
  }
  80% {
    color: white;
  }
  100% {
    color: #F2356D;
  }
}

@-webkit-keyframes blink{
  0% {
    color: white;
  }
  20% {
    color: #F2356D;
  }
  40% {
    color: white;
  }
  60% {
    color: #F2356D;
  }
  80% {
    color: white;
  }
  100% {
    color: #F2356D;
  }
}

a.custom{

  color:#F2356D;
}
a.custom:hover{
  animation: blink 5s infinite linear;
}

/*default-btn*/
.default-btn-custom {
  border: none;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  color: white;
  background-color: #F2356D;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  padding: 12px 25px 12px 25px;
  font-weight: 700;
  font-size: 16px;
}

.progress-section-large {
  display: flex;
  width: 16%;
  flex-direction: column;
}

.progress-section-small {
  display: flex;
  width: 13%;
  flex-direction: column;
}

.progress-bar-section {
  display: flex;
  flex-direction: row;
}
.progress-bar {
  width: 0%;
  height: 7px;
  background-color: #00ffe5;
}

.progress-bar-pending {
  flex-grow: 1;
  height: 7px;
  background-color: orange;
}

#progress {
  display: flex;
  flex-direction: row;
  padding-left: 9%;
  width: 95%;
  margin-top: 1em;
  color: white;
}


.progress-tick {
  width: 2px;
  height: 21px;
  background-color: #c4c4c4;
  margin-top: -14px;
  align-self: flex-end;
}

.progress-tick-end {
  width: 2px;
  height: 21px;
  background-color: rgba(0, 0, 0, 0);
  margin-top: -14px;
  align-self: flex-end;
}

.stage-name {
  text-align: center;
  line-height: 25px;
  font-size: 0.8em;
}

.stage-price {
  text-align: center;
  line-height: 25px;
  font-size: 0.6em;
}

.img1 {
    width: auto;
    height: 350px;
    margin: 0.10rem;
}
.img2 {
  width: auto;
  height: 290px;
  margin: 0.10rem;
}

.header-img {
 width: auto;
 height: 80px;
 -webkit-transition: 1s;
 transition: 1s;
}

.header-img:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);

}

.banner-img {
  -webkit-transition: 1s;
  transition: 1s;
 }
 
 .banner-img:hover {
   -webkit-transform: translateY(-5px);
           transform: translateY(-5px);
 
 }

.banner-wrapper-content  .btn-box .default-btn-custom {
  top: -.3px;
  margin-left: 15px;
  background-color: transparent;
  z-index: 1;
  height: 47px;
  color: #000000;
}

.banner-wrapper-content .btn-box .default-btn-custom::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  border-radius: 5px;
  bottom: 0;
  left: 0;
  border: 1px solid #ffffff;
}

.banner-wrapper-content .btn-box .default-btn-custom:hover {
  color:  #ffffff;
}

.bx-custom {
  font-size: 20px !important;
}

.custom-followus {
  color: #ffffff;
  margin-left: 10px;

}

.circle-custom { 
  background: #DBD2D0; 

  border-radius: 10px;
}

.circle-custom-artwork { 
  border-radius: 12px;
}

.bx-instagram-custom {
  color: #F2356D;
  font-size: 98px;
}

.bx-large-custom {
  color: #F2356D;
  font-size: 98px;
}

.bx-normal-custom {
  color: #F2356D;
  font-size: 80px;
}

.bx-normal-white-custom {
  color: white;
  font-size: 80px;
}

.bx-small-custom {
  color: #F2356D;
  font-size: 40px;
}

.bg-282d41 {
  background-color: #282d41;
}

.bg-FF13A7 {
  background-color: #F2356D;
}

.bg-color-303644 {
  background-color: #303644;
}

.bg-color-darkslateblue {

  background-color: darkslateblue;
}

.bg-color-dark {

  background-color: black;
}

.bg-blurple {
  background-color: #5865F2
}

.bg-pink {
  background-color: #dd2a7b
}

.bg-light-qaua {
  background-color:#69C9D0
}

.color-F2356D {
  color: #F2356D;
}

.color-F2356D {
  color: #F2356D;
}

.font-size-12px {
  font-size: 12px;
}

.color-FFFFFF {
  color: #FFFFFF;
}

.color-000000 {
  color: #000000;
}


.cinema-bg {
  width: 100vw; 
  max-width: 100%;
  background-size: cover;
  height:auto;
  background-position-x: left; 
  background-position-y:top;
  min-height:450px;
  color:black
}
.cinema-video {
  object-fit: fill;
  width: 480px;
  height: 280px;
  position: absolute;
  top: 190px;
  left: 350px;

}

.roadmap-border-color {
  border-color: #F2356D;
  border-style: solid;
  border-width: 1px;
}

.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: #F2356D; 
 }

.lg\:text-2xl {
  font-size: 1.5rem;
  line-height: 2rem; 
}

.text-white {
  --tw-text-opacity: 1;
   color: rgba(255,255,255,var(--tw-text-opacity));
}

.font-extrabold {
  font-weight: 800;
}

.self-center {
  align-self: center;
}

.bg-tweet {

  background-color: #1DA1F2;
}


.hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(56,62,101,var(--tw-bg-opacity));
}


.flex-even {
  flex: 1;
}

.ptb-custom-100 {
  padding-top: 25px;
  padding-bottom: 100px;
}


.background-frame {
  background-image:url("../../images/frame2.png"); 
  background-size: contain;
  background-repeat: no-repeat;
  background-position:center;
  padding: 12%;


}

.footer-custom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

}

.border-line-bottom {
  border-bottom: 2.5px solid #c5c5c5;
}

.back-ground-text {
  background-color: #F2356D;
  color:white;
}

.btn-primary-mutantmoneysociety {
  color: #fff;
  background-color: #F2356D;
  border-color: #F2356D;
}


.bg-color-dark-blue {
  background-color: #282d41;
}

.bg-color-white {
  background-color: #ffffff;
}

.bg-color-black {
  background-color: black;
}


.bg-color-mint {
  background-color: #303644;
}

.bg-color-95969A {
  background-color: #95969A;
}


.back-ground-gray {
  background-color: #282d41;
}

.pt-50 {
  padding-top: 50px;
}

.pt-20 {
  padding-top: 20px;
}

.w-35 {
  width: 35% !important;
}
.orange-border{
  padding-bottom: 20px;
  border-bottom: 2px solid #F2356D;
}

.orange-top{
  padding-top: 20px;
  border-top: 2px solid #F2356D;
}

.tag-style{
    background: #F2356D;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.about-image-box-custom {
  text-align: center;
  position: relative;
  margin-right: 20px;
  border-radius: 5px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.about-image-box-custom::before {
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  position: absolute;
  background: #F2356D;
  z-index: -1;
  margin-top: 10px;
  margin-left: 35px;
  margin-right: 35px;
}

.size-details-info {
  width: 450px;
  height: 290px;
}

.footer-bottom-area-min {
  margin-top: 0px;
  border-top: 1px solid #f3f2ee;
  padding-top: 1px;
  padding-bottom: 1px;
}

.footer-bottom-area-min p i {
  position: relative;
  top: 1.3px;
}

.footer-bottom-area-min p a {
  color: #F2356D;
  font-weight: 600;
}

.footer-bottom-area-min p a:hover {
  color: #F2356D;
}

.footer-bottom-area-min p strong {
  color: #F2356D;
}

.footer-bottom-area-min ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area-min ul li {
  color: #6b6b84;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area-min ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area-min ul li:last-child::before {
  display: none;
}

.footer-bottom-area-min ul li::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: #6b6b84;
}

.footer-bottom-area-min ul li a {
  display: block;
  color: #6b6b84;
}

.footer-bottom-area-min ul li a:hover {
  color: #F2356D;
}

.tarn-nav .navbar .navbar-brand img {
  max-width: 300px;
}


.footer-bottom-area-custom {
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-top: 1px solid #f3f2ee;
}

.footer-bottom-area-custom ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area-custom ul li {
  color: #6b6b84;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area-custom ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area-custom ul li:last-child::before {
  display: none;
}

/*================================================
right bar at list
=================================================*/
.footer-bottom-area-custom ul li::before {
  content: '';
  position: absolute;
  right: -12px;
  top: 5.6px;
  width: 1px;
  height: 14px;
  background-color: #6b6b84;
}

.footer-bottom-area-custom ul li a {
  display: block;
  color: #6b6b84;
}

.footer-bottom-area-custom ul li a:hover {
  color: #F2356D;
}





/*================================================
new custom
=================================================*/
.footer-bottom-area-custom-vertical {
  margin-top: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-top: 1px solid #f3f2ee;
}

.footer-bottom-area-custom-vertical ul {
  text-align: right;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.footer-bottom-area-custom-vertical ul li {
  color: white;
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.footer-bottom-area-custom-vertical ul.vertical li {
  color: white;
  position: relative;
  display: block;
  margin-right: 20px;
}

.footer-bottom-area-custom-vertical ul li:last-child {
  margin-right: 0;
}

.footer-bottom-area-custom-vertical ul li:last-child::before {
  display: none;
}

.footer-bottom-area-custom-vertical ul li a {
  display: block;
  color: white;
}

.footer-bottom-area-custom-vertical ul li a:hover {
  color: #F2356D;
}

.projects-area div .cella-font {
  font-family: cella;
  color: #000000;
  font-size: 20px;
}

.red-underline {
  background-color: #F2356D;
  --lnw: 5px;
  --brd: var(--color_18);
  --alpha-brd: 1;
  transform-origin: center 2.5px;
  width: 43px;
  height: 5px;

}

.font-Nunito {
  font-family: 'Nunito';
}

.rockwell-font {
  font-family: Rockwell;
}


.rounded-full {
  background-color: #F2356D;
  border-radius: 9999px;
  width: .75rem;
  height: .75rem;
  padding: 5px
}


.trapizium {
  padding: 5px;
  font-size: 18px;
  line-height: 1;
  width: 100%;
  text-align: center;
  position: relative;
  background: #282d41;
}

.trapizium:before {
  content: "";
  position: absolute;
  border: none;
  top: -0px;
  bottom: -50%;
  left: 0px;
  right: 0px;
  z-index: -1;
  background: #282d41;
  transform: perspective(20em) rotateX(-45deg);
  transform-origin: top;
}
/*================================================
How to buy Area CSS
=================================================*/
.how-to-buy-image-item img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.how-to-buy-image-item:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}


/*================================================
SVG 
=================================================*/
.svgcustom {
  fill:#F2356D;

}
.svgcustom:hover,
.svgcustom:focus {
  fill: #F2356D;
}

.svgcustom-title {
  width: 165px;
}

.solutions-area .section-title {
  max-width: 800px;
}





@keyframes animloader {
  0% {
    top: 0;
    color: #F2356D;
  }
  50% {
    top: 30px;
    color: rgba(255, 255, 255, 0.2);
  }
  100% {
    top: 0;
    color:#F2356D;
  }
}
@media (max-width: 768px) {
  .btn-responsive {
    margin-bottom: 25px; 
    margin-left: 0px;
    padding-left: 40px;
    height: 40px;
    background-color: white;
    color:#F2356D ;
  }

  .bx-custom {
    left: 23px !important;
  }

}

@media (min-width: 769px) {
  .btn-responsive {
    margin-bottom: 25px; 
    margin-left: 0px;
    padding-left: 40px;
    height: 40px;
    background-color: white ;
    color: #F2356D ;
  }

  .bx-custom {
    left: 20px;
  }

}